import React from 'react'
import { graphql } from 'gatsby'
// We're using Gutenberg so we need the block styles
// these are copied into this project due to a conflict in the postCSS
// version used by the Gatsby and @wordpress packages that causes build
// failures.
// @todo update this once @wordpress upgrades their postcss version
import '../css/@wordpress/block-library/build-style/style.css'
import '../css/@wordpress/block-library/build-style/theme.css'
import Layout from '../components/layout'
import Seo from '../components/seo'

const LegalTemplate = ({ data: { legal } }) => {
  return (
    <Layout>
      <Seo title={`${legal.title}`} noindex />
      <div className="container-narrow py-3 py-lg-4 py-xl-5">
        <header>
          <h1 className="m-0 p-0">{legal.title}</h1>
        </header>
        <main className="pt-1" dangerouslySetInnerHTML={{ __html: legal.content }} />
      </div>
    </Layout>
  )
}

export default LegalTemplate

export const pageQuery = graphql`
query LegalPostById(
    $id: String!
  ) {
    legal: wpLegalDocument(id: { eq: $id }) {
      content
      title
    }
  }
`
